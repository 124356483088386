<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
    style="list-style-type: none;"
  >
    <template #button-content>
      <b-button
        variant="success"
      ><feather-icon icon="MessageCircleIcon" />
      </b-button>

    </template>

    <b-dropdown-item
      v-for="(template, index) in templates"
      :key="index"
      :href="`https://wa.me/${phoneNumber}?text=${encodeURIComponent(template.template)}`"
      target="_blank"
      link-class="d-flex align-items-center"
    >
      <span>{{ template.name }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :href="`https://wa.me/${phoneNumber}`"
      target="_blank"
    >
      Nova mensagem
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script lang="ts">
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BButton,
} from 'bootstrap-vue'

export default {
  name: 'WhatsappMenu',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
  },
  props: {
    templates: {
      type: Array,
      required: true,
    },
    phoneNumber: {
      type: String,
      required: true,
    },
  },
}
</script>
