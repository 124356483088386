<template>
  <b-card-actions
    ref="UsersList"
    title="Usuários"
    class="top-level"
    action-refresh
    @refresh="updateRows({})"
  >
    <b-row align-v="start">

      <b-col md="3">
        <b-form-group>
          <label>Pesquisar</label>
          <b-form-input
            v-model="$store.state.users.filters.search"
            :disabled="state === 'loading'"
            placeholder="Pesquisar"
            type="text"
            class="d-inline-block"
            autocomplete="off"
            @change="(value)=>pesquisa()"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-row class="mb-1 mb-md-0">
      <b-col
        cols="12"
        md="6"
      >
        <b>{{ $store.state.users.usersList.length }}</b> de <b>{{ $store.state.users.totalCount }}</b> Usuários
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-form-group
          label="Selecionar tudo"
          label-cols="9"
        >
          <b-form-checkbox
            v-model="allSelected"
            class="mr-0 mt-50"
            name="all-selected"
            switch
            inline
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          class="float-right"
          variant="success"
          :to="{ name : 'order-create'}"
          :disabled="true"
        >
          Novo usuário
        </b-button>
      </b-col>
    </b-row>
    <div>
      <b-card
        v-for="user in rows"
        :key="user.id"
        :class="`mb-1 list-row ${selected.includes(user.id) ? 'row-selected' : ''} ${isBirthDay(user.birth_date) ? 'birthday' : ''}`"
        @click="selectCard(user.id)"
      >
        <b-row align-v="center">
          <b-col
            lg="4"
            cols="12"
          >
            <span
              class="text-nowrap flex-parent"
            >
              <b-avatar
                :src="user.gravatar_url"
                class="mx-1"
              /> <span class="flex-child long-and-truncated">{{ user.first_name ? `${user.first_name} ${user.last_name}` : user.email }}</span>
            </span>
          </b-col>
          <b-col
            lg="2"
            cols="6"
          >
            <span style="font-size: 10px;">Admin</span><br>
            <feather-icon
              v-if="user.is_staff"
              icon="CheckIcon"
              class="text-success"
              size="18"
            />
            <feather-icon
              v-else
              icon="XIcon"
              class="text-danger"
              size="18"
            />
          </b-col>
          <!-- <b-col
            lg="2"
            cols="6"
          >
            <span style="font-size: 10px;">Cadastro</span><br>
            {{ dateFormater.format(new Date(user.date_joined)) }}
          </b-col> -->
          <b-col
            lg="2"
            cols="6"
          >
            <b-col
              lg="1"
              cols="6"
              class="mt-0 text-center"
            >
              <whatsapp-menu
                :templates="$store.state.whatsapp.messageTemplates"
                :phone-number="`+55${(user.address_set && user.address_set.length) ? user.address_set[0].phone.replace(/\D/g,'') : ''}`"
                @click.stop="() => {}"
              />
            </b-col>
          </b-col>
          <b-col
            lg="2"
            cols="6"
          >
            <span style="font-size: 10px;">Pedidos</span><br>
            {{ user.orders.count }}
          </b-col>
          <b-col
            lg="2"
            cols="6"
          >
            <span style="font-size: 10px;">Último pedido</span><br>
            {{ user.orders.count ? dateFormater.format(new Date(user.orders.last)) : '' }}
          </b-col>

        </b-row>
        <b-row
          v-if="selected.includes(user.id) && selected.length === 1"
          align-v="center"
          class="pb-1"
        >
          <b-col
            lg="3"
            cols="12"
          >
            <span style="font-size: 10px;">Aniversário</span><br>
            {{ user.birth_date ? bithdayFormat(user.birth_date) : '-' }}
          </b-col>
          <b-col
            lg="3"
            cols="12"
          >
            <span style="font-size: 10px;">E-mail</span><br>
            <ul>
              <li
                v-for="email in user.emailaddress_set"
                :key="email"
              >
                {{ email }}
              </li>
            </ul>
          </b-col>
          <b-col
            v-if="user.address_set"
            lg="3"
            cols="12"
          >
            <span style="font-size: 10px;">Telefone</span><br>
            <ul>
              <li
                v-for="address in getUniqueElements(user.address_set, 'phone')"
                :key="address.id"
              >
                {{ address.phone }}
              </li>
              <li v-if="!getUniqueElements(user.address_set, 'phone').length">
                -
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div class="d-flex justify-content-between flex-wrap mb-1">
      <div class="d-flex align-items-center mb-0 mt-1">
        <span class="text-nowrap">
          Tamanho da página
        </span>
        <b-form-select
          v-model="$store.state.users.filters.pageSize"
          :disabled="state === 'loading'"
          :options="['1','5','10', '50', '100', '250']"
          class="mx-1"
          @input="(value)=>updateRows({ pageSize: value, page: 1 })"
        />
      </div>
      <div>
        <b-pagination
          v-model="$store.state.users.filters.page"
          :disabled="state === 'loading'"
          :total-rows="$store.state.users.totalCount"
          :per-page="$store.state.users.filters.pageSize"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value)=>updateRows({ page: value })"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </div>
  </b-card-actions>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BButton, VBPopover, BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BForm,
  BFormCheckbox, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import WhatsappMenu from '@/components/WhatsappMenu.vue'

export default {
  components: {
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
    BForm,
    BButton,
    BCardActions,
    BCard,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    WhatsappMenu,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      allSelected: false,
      selected: [],
      searchFilter: null,
      state: 'ready',
      dir: false,
      allSelectState: false,
    }
  },
  computed: {
    rows() {
      return this.$store.state.users.usersList
    },
  },
  watch: {
    allSelected(value) {
      if (value) {
        this.selected = this.$store.state.orders.ordersList.map(item => item.id)
        this.allSelectState = false
      } else if (this.allSelectState) {
        this.allSelectState = false
      } else {
        this.selected = []
      }
    },
    selected(value) {
      if (value.length !== this.$store.state.orders.ordersList.length) {
        this.allSelectState = true
        this.allSelected = false
      }
    },
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
      // timeZoneName: 'short',
      // hour: 'numeric',
      // minute: '2-digit',
      timeZone: 'America/Sao_Paulo',
    })
    if (this.$store.state.users.usersList.length === 0) {
      this.pesquisa()
    }
    this.searchFilter = this.$store.state.users.filters.search ? this.$store.state.users.filters.search : ''
    if (this.$store.state.whatsapp.messageTemplates.length === 0) {
      this.$store.dispatch('whatsapp/fetchMessageTemplates')
    }
  },
  methods: {
    bithdayFormat(dateString) {
      return dateString.split('-').reverse().join('/')
    },
    isBirthDay(dateString) {
      try {
        const date = dateString.split('-')
        const today = new Date()

        if (
          today.getMonth() + 1 === +date[1]
          && today.getDate() === +date[2]
        ) {
          return true
        }
        return false
      } catch (e) {
        return false
      }
    },
    selectCard(id, unique = true) {
      const index = this.selected.indexOf(id)
      if (unique) {
        if (index > -1) {
          this.selected = []
        } else {
          this.selected = [id]
        }
      } else if (index > -1) {
        this.selected.splice(index, 1)
      } else {
        this.selected.push(id)
      }
    },
    pesquisa() {
      this.state = 'loading'
      try {
        this.$refs.UsersList.showLoading = true
      } catch (error) {

      }
      this.$store.commit('users/firstPage')
      this.$store.dispatch('users/getUsers', {}).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.users.UsersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.UsersList.showLoading = false
        } catch (error) {

        }
      })
    },
    updateRows(filters) {
      this.state = 'loading'
      try {
        this.$refs.UsersList.showLoading = true
      } catch (error) {

      }
      this.$store.dispatch('users/getUsers', filters).finally(() => {
        this.selected = this.selected.filter(item => this.$store.state.orders.ordersList.find(order => order.id === item))
        this.state = 'ready'
        try {
          this.$refs.UsersList.showLoading = false
        } catch (error) {

        }
      })
    },
    getUniqueElements(arr, keyName) {
      return [...new Map(arr.map(item => [item[keyName], item])).values()]
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.birthday{
  background-color: #9dffc4;
}

.card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.list-row:hover {
  background: rgb(226, 226, 226);
  color: inherit;
}

.row-selected {
  background: #004259;
  color: white !important;
}

.row-selected:hover {
  background: #004159cc;
  color: white;
}

.flex-parent {
  display: flex;
  align-items: center;
  padding: 10px;
  // margin: 1em 0;
}

.long-and-truncated {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#filter_status{
  border-radius: 6px;
}
</style>
